<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
        <PageHeader :title="title" />
        <v-row>
          <v-col
            cols="12"
            :md="showHelp ? 12 : 6"
            sm="3"
            class="pr-0"
            v-for="item in views"
            :key="item.id"
          >
            <v-card
              color="primary"
              dark
              @click="whereToPush(item)"
              height="100px"
            >
              <v-card-title class="text-h5">
                {{ item.title }}
              </v-card-title>

              <v-card-subtitle>{{ item.subtitle }} </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-3 pr-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "ProcesosDevengamientos",
  components: { PageHeader, Ayuda },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como pagina visitada si ingreso por url
      this.optionCode
    );
    this.setPermisos();
    this.views.sort(function (a, b) {
      return a.order - b.order;
    });
  },
  data() {
    return {
      title: enums.titles.PROCESOS_DEVENGAMIENTOS,
      allowedActions: null,
      optionCode: enums.webSiteOptions.PROCESOS_DEVENGAMIENTOS,
      views: [],
      showExpand: false,
      showHelp: false,
      showIcon: true,
    };
  },
  methods: {
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.webSiteOptions.DEVENGAMIENTOS_POR_GRUPO:
            this.views.push({
              id: -1,
              title: x.title,
              order: 1,
              subtitle:
                "Devengamiento por agente de cuenta o grupo de devengamiento.",
              path: "mvc",
              params: `${window.location.origin}/redirto?id=GestionDevengamiento/ConsultarDevengamiento`,
            });
            break;
          case enums.webSiteOptions.DEVENGAMIENTOS_DESDE_ARCHIVOS:
            this.views.push({
              id: -2,
              title: x.title,
              order: 2,
              subtitle: "Procesos de devengamiento importando archivos Excel.",
              path: "mvc",
              params: `${window.location.origin}/redirto?id=ImportacionDevengamiento/Consulta`,
            });
            break;
          case enums.webSiteOptions.GENERACION_REGLAS_COMERCIALES:
            this.views.push({
              id: -3,
              title: x.title,
              order: 3,
              subtitle:
                "Genera bonificaciones, recargos o conceptos a devengar según la configuración de reglas comerciales.",
              path: "mvc",
              params: `${window.location.origin}/redirto?id=ReglasComerciales/ConsultarProcesosReglasComerciales`,
            });
            break;
          case enums.webSiteOptions.DEVENG_COSEGUROS_WEB:
            this.views.push({
              id: -4,
              title: x.title,
              order: 4,
              subtitle:
                "Procesos para enviar coseguros a la cuenta corriente o a conceptos a devengar",
              path: "DevengamientoCosegurosWeb",
            });
            break;
          case enums.webSiteOptions.DEVENG_ADIC_EXTRA:
            this.views.push({
              id: -5,
              title: x.title,
              order: 5,
              subtitle:
                "Genera en cuenta corriente comprobantes adicionales a los devengamientos por grupo",
              path: "DevengamientoAdicionalesExtra",
            });
            break;
        }
      });
    },
    whereToPush(item) {
      if (item.id < 0) {
        this.$router.push({
          name: item.path,
          params: { url: encodeURIComponent(item.params) },
        });
      } else {
        this.$store.dispatch("user/changeAppLoading", {
          status: true,
          text: "",
          opaque: true,
        });
        this.$router.push({
          name: item.path,
        });
      }
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
  },
};
</script>

<style scoped>
::v-deep .text-h5 {
  font-size: 1.35rem !important;
  font-weight: 450;
  line-height: 2rem;
  letter-spacing: normal !important;
  font-family: "Roboto", sans-serif !important;
}
</style>
